<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/dwa_logo.svg">
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
})
export default class Home extends Vue {}
</script>
